import HttpService from '@app/api/http-service';
import { Reel } from '@app/api/resources/Reel';
import { ObjectOfStrings } from '@app/api/utility-types';

export type PlaybackLanguages = {
  audio_options: string[];
  subtitle_options: string[];
  reels: Reel[];
  media_options: {
    duration: number;
    hd: boolean;
  };
  media_features: ('4K' | 'HD' | '5.1' | 'atmos')[];
};

export const getPlaybackLanguagesForFilm = (
  httpContext: ObjectOfStrings,
  filmId: number,
): {
  data: PlaybackLanguages;
} => HttpService(httpContext).get(`/films/${filmId}/playback_languages`);
