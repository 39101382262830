import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import {
  CastCredit,
  mapCreditToDisplayCredit,
} from '@app/api/resources/CastMember';

import ShowAllLink from '@app/components/ShowAllLink';

type CastCreditSectionTopProps = {
  castSlug: string;
  castCredit: CastCredit;
  numberOfTilesToShow: number;
};

const CastCreditSectionTop = ({
  castSlug,
  castCredit,
  numberOfTilesToShow,
}: CastCreditSectionTopProps) => {
  const { t } = useTranslation('cast_show');
  const showMoreLink = `/cast/${castSlug}/films/${castCredit.type}`;
  return (
    <TopSection>
      <CreditTitle>{mapCreditToDisplayCredit(castCredit, t)}</CreditTitle>
      {castCredit.film_count > numberOfTilesToShow && (
        <ShowAllLink count={castCredit.film_count} url={showMoreLink} />
      )}
    </TopSection>
  );
};

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const CreditTitle = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: ${props => props.theme.color.lightText};
  line-height: 1;
`;

export default CastCreditSectionTop;
