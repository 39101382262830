import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import CastCreditNowShowingTile from '@app/components/cast-show/CastCreditNowShowingTile';
import TilesList from '@app/components/grid-layout/TilesList';
import PageSection from '@app/components/layout/PageSection';
import ShowAllLink from '@app/components/ShowAllLink';

type CastCreditNowShowingSectionProps = {
  nowShowingFilms: Film[];
  nowShowingFilmsTotalCount: number;
  castMemberId: number;
  castSlug: string;
};

const CastCreditNowShowingSection = ({
  nowShowingFilms,
  nowShowingFilmsTotalCount,
  castMemberId,
  castSlug,
}: CastCreditNowShowingSectionProps) => {
  const { t } = useTranslation('cast_show');

  return (
    <Container>
      <PageSection>
        <TopSection>
          <Title>{t('cast_show:cast_show.available_to_watch')}</Title>
          {nowShowingFilmsTotalCount >= 5 && (
            <ShowAllLink
              count={nowShowingFilmsTotalCount}
              url={`/cast/${castSlug}/films/available`}
            />
          )}
        </TopSection>
        <TilesList>
          {nowShowingFilms.map((nowShowingFilm, i) => (
            <CastCreditNowShowingTile
              key={`now-showing-${nowShowingFilm.id}`}
              film={nowShowingFilm}
              castMemberId={castMemberId}
              preloadFilmStill={i === 0}
            />
          ))}
        </TilesList>
      </PageSection>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 22px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 43px;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: ${props => props.theme.color.lightText};
  line-height: 1;
`;

export default CastCreditNowShowingSection;
