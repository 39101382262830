import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import WideFilmTileWithImpressionTracking from '@app/components/film-tile/wide-film-tile/WideFilmTileWithImpressionTracking';

type CastCreditNowShowingTileProps = {
  film: Film;
  castMemberId: number;
  preloadFilmStill?: boolean;
};

const CastCreditNowShowingTile = ({
  film,
  castMemberId,
  preloadFilmStill = false,
}: CastCreditNowShowingTileProps) => {
  const activeSubscriber = useAppSelector(state => state.user.activeSubscriber);
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const trackEvent = clickType =>
    trackSnowplowClickEvent({
      data: {
        clickType,
        element: 'film_tile',
      },
      extraData: {
        cast_crew_id: castMemberId,
      },
      film,
    });

  return (
    <Container>
      <WideFilmTileWithImpressionTracking
        film={film}
        showFilmActionGroupButtons
        showWatchListButton
        onTileClick={() => trackEvent('film_tile')}
        onPlayButtonClick={() => activeSubscriber && trackEvent('film_play')}
        preloadFilmStill={preloadFilmStill}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${props => props.theme.grid.gridMarginBottom}px;
  width: 100%;
`;

export default CastCreditNowShowingTile;
