import { isWideLargeWidth } from '@app/services/breakpoints';

import { ValueOf } from '@app/types/utility-types';

export const perPageSizesUnderWideLarge = {
  SMALL: 12,
  MEDIUM: 24,
  LARGE: 48,
} as const;
const perPageSizesWideLargeOrAbove = {
  SMALL: 15,
  MEDIUM: 30,
  LARGE: 60,
} as const;

type PageSize =
  | keyof typeof perPageSizesUnderWideLarge
  | keyof typeof perPageSizesWideLargeOrAbove;

const useGetPerPageSize = (
  pageSize: PageSize = 'MEDIUM',
): ValueOf<
  typeof perPageSizesUnderWideLarge | typeof perPageSizesWideLargeOrAbove
> =>
  isWideLargeWidth()
    ? perPageSizesWideLargeOrAbove[pageSize]
    : perPageSizesUnderWideLarge[pageSize];

export default useGetPerPageSize;
