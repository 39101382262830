import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { FilmId } from '@app/api/resources/Film';
import {
  getPlaybackLanguagesForFilm,
  PlaybackLanguages,
} from '@app/api/resources/PlaybackLanguages';

import { setPlaybackLanguagesByFilmId } from '@app/actions/PlaybackLanguagesActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const usePopulatePlaybackLanguagesForFilmId = (
  filmId: FilmId,
): [PlaybackLanguages, boolean, boolean] => {
  const { httpContext, playbackLanguagesByFilmId } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      playbackLanguagesByFilmId:
        state.playbackLanguages?.playbackLanguagesByFilmId,
    }),
    shallowEqual,
  );
  const initialPlaybackLanguages: PlaybackLanguages =
    playbackLanguagesByFilmId?.[filmId];

  const [playbackLanguages, setPlaybackLanguages] = useState(
    initialPlaybackLanguages || null,
  );
  const [isLoading, setIsLoading] = useState(!initialPlaybackLanguages);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const doPopulatePlaybackLanguagesForFilmId = async () => {
    setIsLoading(true);
    const playbackLanguagesFromStore = playbackLanguagesByFilmId?.[filmId];

    if (!playbackLanguagesFromStore) {
      setPlaybackLanguages(null);
      try {
        const playbackLanguagesResponse = await getPlaybackLanguagesForFilm(
          httpContext,
          filmId,
        );
        const retrievedPlaybackLanguages = playbackLanguagesResponse.data;
        dispatch(
          setPlaybackLanguagesByFilmId(retrievedPlaybackLanguages, filmId),
        );
        setPlaybackLanguages(retrievedPlaybackLanguages);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setPlaybackLanguages(playbackLanguagesFromStore);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filmId) {
      doPopulatePlaybackLanguagesForFilmId();
    } else {
      setPlaybackLanguages(null);
    }
  }, [filmId]);

  return [playbackLanguages, isLoading, isError];
};

export default usePopulatePlaybackLanguagesForFilmId;
