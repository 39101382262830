import { memo, ReactNode, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Director } from '@app/api/resources/CastMember';

import { color as themeColors } from '@app/themes/mubi-theme';

import CastMemberLink from '@app/components/CastMemberLink';
import TooltipPosition from '@app/components/tool-tip/TooltipPosition';

/**
 * FilmDirectors component
 *
 * Takes an array of Director objects, and renders them in a comma separated list,
 * with "& X more" if necessary
 */

const Container = styled.span`
  font-weight: bold;
`;

const MouseListener = styled.div`
  display: inline-block;
`;

const StyledTooltip = styled(TooltipPosition)`
  color: ${themeColors.lightText};
  white-space: normal;
  font-weight: normal;
  text-transform: uppercase;
  padding: 15px;
`;

const renderDirectorsAsText = (directors: Director[]) =>
  directors.map(d => d.name).join(', ');

const renderDirectorsAsLinks = (
  directors: Director[],
  isUppercase?: boolean,
) => {
  if (directors.length === 0) {
    return [];
  }
  return directors
    .map(director =>
      getDirectorLinkComponent(director, themeColors.white, isUppercase),
    )
    .reduce(insertCommas);
};

const insertCommas = (prev, next) => [prev, ', ', next];

const getDirectorLinkComponent = (
  director: Director,
  color: string,
  isUppercase?: boolean,
): ReactNode => (
  <CastMemberLink
    key={director.slug}
    castMember={director}
    castMemberRole="director"
    color={color}
    isUppercase={isUppercase}
  />
);

type FilmDirectorsProps = {
  directors: Director[];
  maxToShow?: number;
  showXMore?: boolean;
  listAsLinks?: boolean;
  enableMoreDirectorsHover?: boolean;
  className?: string;
  isUppercase?: boolean;
};

const FilmDirectors = ({
  directors = [],
  maxToShow = 4,
  showXMore = true,
  listAsLinks,
  enableMoreDirectorsHover,
  className,
  isUppercase,
}: FilmDirectorsProps) => {
  const { t } = useTranslation('common');
  const [hovering, setHovering] = useState(false);
  const getNumberToShow = (truncate: boolean) => {
    if (truncate) {
      // normally, if we're showing the "& X more" suffix, we display one less name to make space
      // but we can't do that if max is set to 1
      return maxToShow === 1 ? 1 : maxToShow - 1;
    }
    return directors.length;
  };

  if (!directors) {
    return null;
  }

  const truncate = directors.length > maxToShow;
  const numberToShow = getNumberToShow(truncate);
  const numberNotShown = directors.length - numberToShow;
  const firstFewDirectors = listAsLinks
    ? renderDirectorsAsLinks(directors.slice(0, numberToShow), isUppercase)
    : renderDirectorsAsText(directors.slice(0, numberToShow));
  const andXMoreText = t('common:common.film_labels.and_x_more', {
    count: numberNotShown,
  });

  const renderAndXMore = () => {
    if (!truncate || !showXMore) {
      return null;
    }
    if (!enableMoreDirectorsHover) {
      return andXMoreText;
    }
    const extraDirectors = renderDirectorsAsText(directors.slice(numberToShow));
    return (
      <MouseListener
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <StyledTooltip message={hovering ? extraDirectors : ''}>
          {andXMoreText}
        </StyledTooltip>
      </MouseListener>
    );
  };
  const andXMore = renderAndXMore();

  return (
    <Container className={className}>
      {firstFewDirectors} {andXMore}
    </Container>
  );
};

export default memo(FilmDirectors);
