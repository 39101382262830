import { FilmId } from '@app/api/resources/Film';
import { PlaybackLanguages } from '@app/api/resources/PlaybackLanguages';

import * as actionTypes from '@app/actionTypes';

export const setPlaybackLanguagesByFilmId = (
  playbackLanguages: PlaybackLanguages,
  filmId: FilmId,
) => ({
  type: actionTypes.SET_PLAYBACK_LANGUAGE_BY_FILM_ID,
  payload: {
    playbackLanguages,
    filmId,
  },
});
