import { getFullRouteUrl } from '@app/services/routeHelpers';
import {
  getFacebookShareUrl,
  getTwitterShareUrl,
} from '@app/services/social-media';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

import SocialButtons from '@app/components/SocialButtons';

type CastShowSocialButtonsProps = {
  castSlug: string;
};

const CastShowSocialButtons = ({ castSlug }: CastShowSocialButtonsProps) => {
  const i18nUrlData = useI18nUrlData();

  const url = getFullRouteUrl({
    url: `/cast/${castSlug}`,
    i18nUrlData,
  });

  return (
    <SocialButtons
      facebookUrl={getFacebookShareUrl(url)}
      twitterUrl={getTwitterShareUrl(url)}
      spaceBetweenIconsMobile="20px"
      spaceBetweenIconsTablet="20px"
      iconWidth="19px"
    />
  );
};

export default CastShowSocialButtons;
