import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import Link from '@app/components/Link';
import ResponsiveBackgroundImage from '@app/components/responsive-images/ResponsiveBackgroundImage';

type ShowMoreFilmsTileProps = {
  showMoreLink: string;
  filmStillUrl: string;
  numberOfFilmsToShow: number;
};

const ShowMoreFilmsTile = ({
  showMoreLink,
  filmStillUrl,
  numberOfFilmsToShow,
}: ShowMoreFilmsTileProps) => {
  const { t } = useTranslation('cast_show');
  return (
    <ContainerLink href={showMoreLink}>
      <HoverDarken>
        <ResponsiveBackgroundImage imageUrl={filmStillUrl} imageHeight="100%" />
      </HoverDarken>
      <TextContent>
        <Number>+{numberOfFilmsToShow}</Number>
        {t('cast_show:cast_show.more_films')}
      </TextContent>
    </ContainerLink>
  );
};

const HoverDarken = styled.div`
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    transition: background 0.2s;
  }
`;

const ContainerLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;

  &:hover {
    ${HoverDarken} {
      &:before {
        background: rgba(0, 0, 0, 0.67);
      }
    }
  }
`;

const TextContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.color.white};
  font-size: 20px;
`;

const Number = styled.div`
  font-size: 60px;
  line-height: 60px;
`;

export default ShowMoreFilmsTile;
