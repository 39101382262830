import { useEffect, useState } from 'react';

import { Meta } from '@app/api/pagination';
import {
  CastFilmsSortBy,
  CastFilmsSortPeriod,
  getCastMembersFilms,
} from '@app/api/resources/CastMember';
import { Film } from '@app/api/resources/Film';
import { ErrorResponse } from '@app/api/resources/Responses';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type UseCastMembersFilmsProps = {
  castMemberId: number;
  filterByCreditType: string;
  pageNum?: number;
  perPage?: number;
  sortBy?: CastFilmsSortBy;
  sortPeriod?: CastFilmsSortPeriod;
};
const useCastMembersFilms = ({
  castMemberId,
  filterByCreditType,
  pageNum = 1,
  perPage = 20,
  sortBy = null,
  sortPeriod = null,
}: UseCastMembersFilmsProps): {
  castMembersFilmsPage: {
    films: Film[];
    meta: Meta;
  };
  error: ErrorResponse;
  isFetching: boolean;
} => {
  const [dataCastMemberId, setDataCastMemberId] = useState(null);
  const [castMembersFilmsPage, setCastMembersFilmsPage] = useState(null);
  const [fetchError, setFetchError] = useState<ErrorResponse>(null);
  const [isFetching, setIsFetching] = useState(true);

  const httpContext = useAppSelector(state => state.appState.httpContext);

  const doGetCastMemberFilms = async () => {
    setIsFetching(true);
    try {
      const { data: castMembersFilmsResponse } = await getCastMembersFilms({
        httpContext,
        castMemberId,
        pageNum,
        perPage,
        filterByCreditType,
        sortBy,
        sortPeriod,
      });

      setCastMembersFilmsPage(castMembersFilmsResponse);
      setDataCastMemberId(castMemberId);
    } catch (error) {
      setFetchError(error?.data);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (castMemberId) {
      doGetCastMemberFilms();
    }
  }, [castMemberId, filterByCreditType, pageNum, sortBy, sortPeriod, perPage]);

  if (castMemberId === dataCastMemberId) {
    return { castMembersFilmsPage, error: fetchError, isFetching };
  }
  return { castMembersFilmsPage: null, error: fetchError, isFetching: true };
};

export default useCastMembersFilms;
