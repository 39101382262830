import { Link as ScrollLink } from 'react-scroll';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import {
  CastMember,
  mapCreditToDisplayCredit,
} from '@app/api/resources/CastMember';

import CastShowSocialButtons from '@app/components/cast-show/CastShowSocialButtons';
import ChanelPrize from '@app/components/chanel-prize/ChanelPrize';
import ResponsivePicture from '@app/components/responsive-images/ResponsivePicture';

const prizeWinners = [
  'anna-thorvaldsdottir',
  'eduardo-williams',
  'fox-maxy',
  'ho-tzu-nyen',
  'jung-jae-il',
  'kantemir-balagov',
  'moor-mother',
  'rungano-nyoni',
  'sam-eng',
  'wang-bing',
];

type CastShowHeadProps = {
  castMember: CastMember;
  castSlug: string;
};

const CastShowHead = ({ castMember, castSlug }: CastShowHeadProps) => {
  const { t } = useTranslation('cast_show');

  const primaryCreditTypes = castMember.credits.slice(0, 3).map(credit => (
    <PrimaryCreditListItem key={credit.type}>
      <CustomScrollLink to={credit.type} offset={-30} duration={500} smooth>
        <Credit>{mapCreditToDisplayCredit(credit, t)}</Credit>
      </CustomScrollLink>
    </PrimaryCreditListItem>
  ));

  const shouldShowChanelPrize = () => prizeWinners.includes(castSlug);

  return (
    <Container>
      <ImageContainer>
        <StyledResponsiveProfilePicture
          alt={t('cast_show:cast_show.photo_of', {
            cast_crew_name: castMember.name,
          })}
          src={castMember.image_url || process.env.defaultCastMemberImage}
          sizeInPixels={100}
          responsiveSizesInPixels={{
            tablet: 300,
          }}
          shouldPreload
        />
        <TitleSection isMobile>
          <CastNameTitle>{castMember.name}</CastNameTitle>
          <PrimaryCreditList>{primaryCreditTypes}</PrimaryCreditList>
        </TitleSection>
      </ImageContainer>
      <Content>
        <TitleSection>
          <CastNameTitle>{castMember.name}</CastNameTitle>
          <PrimaryCreditList>{primaryCreditTypes}</PrimaryCreditList>
        </TitleSection>
        <Quote>{castMember.quote}</Quote>
        <FooterActions>
          <SocialButtonsContainer>
            <CastShowSocialButtons castSlug={castSlug} />
          </SocialButtonsContainer>
          {shouldShowChanelPrize() ? (
            <PrizeContainer>
              <ChanelPrize winnerSlug={castSlug} />
            </PrizeContainer>
          ) : null}
        </FooterActions>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 0;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 40px 0 20px;
    display: flex;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-right: 20px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: block;
    margin-right: 50px;
    width: 240px;
    height: 285px;
  }
`;

const Content = styled.div`
  position: relative;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    flex: 1;
  }
`;

const TitleSection = styled.div<{
  isMobile?: boolean;
}>`
  display: ${props => (props.isMobile ? 'block' : 'none')};
  width: calc(100% - 100px);

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: ${props => (props.isMobile ? 'none' : 'block')};
    width: 100%;
  }
`;

const CastNameTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 4px;
  color: ${props => props.theme.color.lightText};
  text-transform: uppercase;
  font-weight: bold;
  line-height: normal;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 40px;
    margin-bottom: 10px;
  }
`;

const PrimaryCreditList = styled.ul`
  margin-top: 8px;
  line-height: 17px;
`;

const PrimaryCreditListItem = styled.li`
  display: inline-block;
  vertical-align: top;

  &:not(:last-child) {
    padding-right: 11px;
    border-right: 1px solid ${props => props.theme.color.lightGray};
    margin-right: 11px;
  }
`;

const Credit = styled.span`
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.2s;
  color: ${props => props.theme.color.lightText};
  font-weight: bold;

  &:hover {
    color: ${props => props.theme.color.darkText};
  }
`;

const Quote = styled.div`
  clear: both;
  padding: 18px 0 34px;
  width: 100%;
  max-width: 450px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 31px 0 60px;
  }
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

const SocialButtonsContainer = styled.div``;

const CustomScrollLink = styled(ScrollLink)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledResponsiveProfilePicture = styled(ResponsivePicture)`
  img {
    width: 80px;
    height: 95px;

    @media (min-width: ${props => props.theme.mqNew.tablet}) {
      width: 240px;
      height: 285px;
    }
  }
`;

const PrizeContainer = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding-right: 5px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding-right: 14px;
  }
`;

export default CastShowHead;
