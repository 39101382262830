import { Element as ScrollElement } from 'react-scroll';
import styled from '@emotion/styled';

import { CastMember } from '@app/api/resources/CastMember';

import CastCreditSection from '@app/components/cast-show/CastCreditSection';
import CastCreditSectionLoading from '@app/components/cast-show/CastCreditSectionLoading';
import PageSection from '@app/components/layout/PageSection';
import OnceVisibleStayVisible from '@app/components/visibility-containers/OnceVisibleStayVisible';

type CastCreditSectionsProps = {
  castMember: CastMember;
  slug: string;
};

const CastCreditSections = ({ castMember, slug }: CastCreditSectionsProps) => (
  <>
    {castMember?.credits?.map(credit => (
      <CastCreditContainer key={credit.type}>
        <ScrollElement name={credit.type} />
        <PageSection>
          <OnceVisibleStayVisible
            partialVisibility
            offset={{
              top: 500,
              bottom: 500,
            }}
          >
            {({ hasBeenVisible }) =>
              hasBeenVisible ? (
                <CastCreditSection
                  castCredit={credit}
                  castMemberId={castMember.id}
                  castSlug={slug}
                />
              ) : (
                <CastCreditSectionLoading castCredit={credit} castSlug={slug} />
              )
            }
          </OnceVisibleStayVisible>
        </PageSection>
      </CastCreditContainer>
    ))}
  </>
);

const CastCreditContainer = styled.div``;

export default CastCreditSections;
