import styled from '@emotion/styled';

import { Director } from '@app/api/resources/CastMember';

import { hasOwnProperty } from '@app/services/utils';

import { color as themeColor } from '@app/themes/mubi-theme';

import Link from '@app/components/Link';

const StyledLink = styled(Link)`
  position: relative;
  z-index: 2;
  color: ${props => props.color};

  &:visited {
    color: ${props => props.color};
  }

  &:hover {
    text-decoration: underline;
    color: ${props => props.color};
  }
`;

type CastMemberLinkProps = {
  castMember: Director;
  color?: string;
  castMemberRole?: string;
  isUppercase?: boolean;
};

const isValidCastMemberItem = castMember => {
  if (
    hasOwnProperty(castMember, 'name') &&
    hasOwnProperty(castMember, 'slug')
  ) {
    return true;
  }
  return false;
};

const CastMemberLink = ({
  castMember,
  color = themeColor.white,
  castMemberRole = '',
  isUppercase,
}: CastMemberLinkProps) => {
  if (!isValidCastMemberItem(castMember)) {
    return null;
  }

  return (
    <StyledLink
      color={color}
      itemScope
      itemProp={castMemberRole}
      itemType="http://schema.org/Person"
      href={`/cast/${castMember.slug}`}
    >
      <meta
        itemProp="url"
        content={`https://mubi.com/cast/${castMember.slug}`}
      />
      <span itemProp="name">
        {isUppercase ? castMember.name_upcase : castMember.name}
      </span>
    </StyledLink>
  );
};

export default CastMemberLink;
