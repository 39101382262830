import { ReactNode, useState } from 'react';
import type { Placement } from '@popperjs/core';

import { isTouchDevice } from '@app/services/device';

import ClickOutside from '@app/components/ClickOutside';
import TooltipPosition from '@app/components/tool-tip/TooltipPosition';

export type TooltipOptionsProps = {
  fontSize?: string;
  padding?: string;
  placement?: Placement;
  zIndex?: string;
  overflowElementId?: string;
  backgroundColor?: string;
  boxShadow?: string;
};

type TooltipProps = {
  uniqId: string | number;
  children: ReactNode;
  message: string | ReactNode;
  className?: string;
  tooltipOptions?: TooltipOptionsProps;
};

const Tooltip = ({
  uniqId,
  children,
  message = '',
  className = null,
  tooltipOptions = null,
}: TooltipProps) => {
  const [showMessage, setShowMessage] = useState(false);

  const {
    fontSize,
    padding,
    placement,
    zIndex,
    overflowElementId,
    backgroundColor,
    boxShadow,
  } = tooltipOptions;

  const mouseSetShowMessage = toShowMessage => {
    if (!isTouchDevice()) {
      setShowMessage(toShowMessage);
    }
  };

  return (
    <ClickOutside
      active={showMessage}
      onClickOutside={() => setShowMessage(false)}
    >
      <span
        onMouseEnter={() => mouseSetShowMessage(true)}
        onMouseLeave={() => mouseSetShowMessage(false)}
        onClick={() => setShowMessage(!showMessage)}
      >
        <TooltipPosition
          uniqId={uniqId}
          message={showMessage ? message : ''}
          className={className}
          fontSize={fontSize}
          padding={padding}
          placement={placement}
          zIndex={zIndex}
          overflowElementId={overflowElementId}
          backgroundColor={backgroundColor}
          boxShadow={boxShadow}
        >
          {children}
        </TooltipPosition>
      </span>
    </ClickOutside>
  );
};

export default Tooltip;
