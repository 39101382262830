import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { CastMember } from '@app/api/resources/CastMember';
import { Film } from '@app/api/resources/Film';

import initialiseCast from '@app/services/page-initialisation/cast';
import { doRedirect, getFullRouteUrl } from '@app/services/routeHelpers';

import { Context } from '@app/types/common';
import { PageErrorCode } from '@app/types/errors';

import useI18nUrlData, {
  getI18nUrlDataFromState,
} from '@app/hooks/helpers/useI18nUrlData';
import usePopulateUserWatchlistForFilms from '@app/hooks/populate-store/usePopulateUserWatchlistForFilms';
import useIsMounted from '@app/hooks/utils/useIsMounted';

import CastCreditNowShowingSection from '@app/components/cast-show/CastCreditNowShowingSection';
import CastCreditSectionLoading from '@app/components/cast-show/CastCreditSectionLoading';
import CastCreditSections from '@app/components/cast-show/CastCreditSections';
import CastShowHead from '@app/components/cast-show/CastShowHead';
import HeaderContainer from '@app/components/layout/HeaderContainer';
import PageSection from '@app/components/layout/PageSection';
import SeoMetaTags from '@app/components/SeoMetaTags';
import SocialMetaTags from '@app/components/SocialMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';

type CastShowProps = {
  castMember: CastMember;
  slug: string;
  castMembersNowShowingFilms?: Film[];
  nowShowingFilmsTotalCount?: number;
};

const CastShow = ({
  castMember,
  slug,
  castMembersNowShowingFilms = [],
  nowShowingFilmsTotalCount = 0,
}: CastShowProps) => {
  const { t } = useTranslation('cast_show');
  const isMounted = useIsMounted();
  const castMembersNowShowingFilmIds =
    castMembersNowShowingFilms?.map(film => film.id) || [];
  usePopulateUserWatchlistForFilms(castMembersNowShowingFilmIds);
  const i18nUrlData = useI18nUrlData();

  const url = getFullRouteUrl({
    url: `/cast/${slug}`,
    i18nUrlData,
  });

  const castCreditSectionsLoading = castMember?.credits?.map(credit => (
    <CastCreditContainer key={`loading-${credit.type}`}>
      <PageSection>
        <CastCreditSectionLoading castCredit={credit} castSlug={slug} />
      </PageSection>
    </CastCreditContainer>
  ));

  return (
    <Container>
      <StandardHeadTags
        title={t('cast_show:cast_show.page_title', {
          cast_crew_name: castMember.name,
        })}
      />
      <SeoMetaTags
        canonicalUrl={url}
        image={castMember.image_url}
        description={t('cast_show:cast_show.page_title', {
          cast_crew_name: castMember.name,
        })}
      />
      <SocialMetaTags
        title={castMember.name}
        type={castMember?.credits?.[0]?.type}
        url={url}
        image={castMember.image_url}
        description={t('cast_show:cast_show.page_title', {
          cast_crew_name: castMember.name,
        })}
      />
      <HeaderContainer />
      <CastShowHeadContainer>
        <PageSection>
          <CastShowHead castMember={castMember} castSlug={slug} />
        </PageSection>
      </CastShowHeadContainer>
      <CastCreditsContainer>
        {castMembersNowShowingFilms.length > 0 && (
          <CastCreditNowShowingSection
            nowShowingFilms={castMembersNowShowingFilms}
            nowShowingFilmsTotalCount={nowShowingFilmsTotalCount}
            castMemberId={castMember.id}
            castSlug={castMember.slug}
          />
        )}
        {isMounted ? (
          <CastCreditSections castMember={castMember} slug={slug} />
        ) : (
          castCreditSectionsLoading
        )}
      </CastCreditsContainer>
    </Container>
  );
};

CastShow.getInitialProps = async ({
  store,
  query,
  isServer,
  res,
}: Context): Promise<CastShowProps | PageErrorCode> => {
  const currentState = store.getState();
  const httpContext = currentState?.appState?.httpContext;

  const {
    castMember,
    castMembersNowShowingFilms,
    nowShowingFilmsTotalCount,
    castMemberNotFound,
    redirectTo,
  } = await initialiseCast(httpContext, query.castSlug as string, store);

  if (redirectTo) {
    doRedirect(
      isServer,
      getFullRouteUrl({
        url: redirectTo,
        i18nUrlData: getI18nUrlDataFromState(currentState),
      }),
      res,
    );
    return null;
  }

  if (castMemberNotFound) {
    return {
      errorStatusCode: 404,
    };
  }

  return {
    castMember,
    castMembersNowShowingFilms,
    nowShowingFilmsTotalCount,
    slug: query.castSlug as string,
  };
};

const Container = styled.div`
  padding-bottom: 50px;
  background-color: ${props => props.theme.color.midBackground};
  min-height: 100vh;
`;

const CastShowHeadContainer = styled.div`
  background-color: ${props => props.theme.color.white};
`;

const CastCreditsContainer = styled.div`
  padding-top: 20px;
`;

const CastCreditContainer = styled.div``;

export default CastShow;
