import styled from '@emotion/styled';

import { CastCredit } from '@app/api/resources/CastMember';

import useGetPerPageSize from '@app/hooks/helpers/useGetPerPageSize';

import CastCreditSectionTop from '@app/components/cast-show/CastCreditSectionTop';
import FilmTileItem from '@app/components/grid-layout/FilmTileItem';
import TilesList from '@app/components/grid-layout/TilesList';

type CastCreditSectionLoadingProps = {
  castSlug: string;
  castCredit: CastCredit;
};
const CastCreditSectionLoading = ({
  castSlug,
  castCredit,
}: CastCreditSectionLoadingProps) => {
  const perPageSize = useGetPerPageSize('SMALL');
  const numFilmTilesToShow =
    castCredit.film_count > perPageSize ? perPageSize : castCredit.film_count;

  const filmTiles = [...Array(numFilmTilesToShow)].map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <FilmTileItem key={`${castCredit.type}-${i}`}>
      <LoadingFilmTile />
    </FilmTileItem>
  ));

  return (
    <Container>
      <CastCreditSectionTop
        castSlug={castSlug}
        castCredit={castCredit}
        numberOfTilesToShow={perPageSize}
      />
      <TilesList>{filmTiles}</TilesList>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 22px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 43px;
  }
`;

const LoadingFilmTile = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.lightGray};
`;

export default CastCreditSectionLoading;
