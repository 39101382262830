import { color as themeColors } from '@app/themes/mubi-theme';

import Svg from '@app/components/icons/Svg';

export const ChanelIcon = ({
  width = '38px',
  color = themeColors.lightText,
}) => (
  <Svg viewBox="0 0 38 28" width={width}>
    <path
      d="M19 26.18A13.608 13.608 0 0 1 12.178 28C7.052 28 2.562 25.194.134 21.033c0-.02-.019-.02-.019-.039s-.02-.019-.02-.038l1.966-1.025 1.966-1.007c.02.02.04.038.058.078 1.677 2.728 4.683 4.547 8.093 4.547 1.1 0 2.159-.193 3.103-.503a13.772 13.772 0 0 1-3.372-9.056c0-3.444 1.253-6.617 3.334-9.056a9.351 9.351 0 0 0-3.065-.503c-3.372 0-6.494 1.819-8.209 4.741-.02.02-.02.04-.02.058L0 7.18a.457.457 0 0 1 .057-.098C2.467 2.845 7.014 0 12.178 0 14.683 0 17.035.677 19 1.839A13.416 13.416 0 0 1 25.821 0c5.165 0 9.713 2.845 12.121 7.082.02.02.039.059.058.097l-3.95 2.05c0-.017 0-.038-.02-.057-1.714-2.922-4.836-4.74-8.209-4.74a9.35 9.35 0 0 0-3.063.502 13.924 13.924 0 0 1 3.334 9.056c0 3.464-1.272 6.637-3.373 9.056.944.31 2.004.503 3.102.503 3.411 0 6.418-1.82 8.094-4.547.02-.04.039-.058.058-.078l1.965 1.007 1.966 1.025c0 .02-.02.02-.02.038 0 .02-.019.02-.019.039C35.437 25.193 30.947 28 25.821 28c-2.504 0-4.855-.678-6.82-1.82Zm0-5.553c-1.62-1.722-2.66-4.063-2.66-6.637A9.33 9.33 0 0 1 19 7.43a9.33 9.33 0 0 1 2.66 6.56c0 2.574-1.042 4.915-2.66 6.637Z"
      fill={color}
      fill-rule="evenodd"
    />
  </Svg>
);
