import { Film } from '@app/api/resources/Film';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import FilmTileWithImpressionTracking from '@app/components/film-tile/default/FilmTileWithImpressionTracking';
import FilmTileItem from '@app/components/grid-layout/FilmTileItem';

type CastCreditSectionTileProps = {
  film: Film;
  castMemberId: number;
};

const CastCreditSectionTile = ({
  film,
  castMemberId,
}: CastCreditSectionTileProps) => {
  const activeSubscriber = useAppSelector(state => state.user.activeSubscriber);
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const trackEvent = clickType =>
    trackSnowplowClickEvent({
      data: {
        clickType,
        element: 'film_tile',
      },
      extraData: {
        cast_crew_id: castMemberId,
      },
      film,
    });

  return (
    <FilmTileItem>
      <FilmTileWithImpressionTracking
        film={film}
        showRatingMode
        showCenteredPlayButtonAndNoHover
        forceWithoutPreviewClipPlayer
        onTileClick={() => trackEvent('film_tile')}
        onPlayButtonClick={() => activeSubscriber && trackEvent('film_play')}
      />
    </FilmTileItem>
  );
};

export default CastCreditSectionTile;
