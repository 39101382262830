import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import {
  ContentRating as ContentRatingTypes,
  ContentWarnings as ContentWarningsTypes,
} from '@app/api/resources/Film';

import useIsMounted from '@app/hooks/utils/useIsMounted';

import ImageBase from '@app/components/ImageBase';
import Tooltip from '@app/components/tool-tip/Tooltip';

type ContentRatingProps = {
  contentRating: ContentRatingTypes;
  contentWarnings: ContentWarningsTypes[];
  uniqId?: string | number;
  overflowElementId?: string;
};

const ContentRating = ({
  contentRating,
  contentWarnings,
  uniqId = 'ContentRating',
  overflowElementId = 'FilmDetailsAboutFilm',
}: ContentRatingProps) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation('film_player');

  const contentRatingImage = contentRating?.icon_url ? (
    <ContentRatingIcon
      src={contentRating.icon_url}
      alt={contentRating.description}
    />
  ) : (
    <ContentRatingLabel>{contentRating.rating_code}</ContentRatingLabel>
  );
  if (!isMounted) {
    return (
      <ContentRatingContainer>{contentRatingImage}</ContentRatingContainer>
    );
  }

  if (contentRating.description || contentWarnings.length) {
    const getContentWarningMessage = () => {
      let contentWarningMessage = t('film_player:film_player.contains').concat(
        ' ',
      );

      contentWarningMessage = contentWarningMessage.concat(
        contentWarnings.map(warning => warning.name).join(', '),
      );

      return contentWarningMessage;
    };

    const message = contentRating.description || getContentWarningMessage();

    return (
      <StyledTooltip
        message={message}
        uniqId={uniqId}
        tooltipOptions={{
          overflowElementId: overflowElementId,
          fontSize: '14px',
          padding: '8px',
          zIndex: '3',
        }}
      >
        <ContentRatingContainer>{contentRatingImage}</ContentRatingContainer>
      </StyledTooltip>
    );
  }

  return contentRatingImage;
};

const ContentRatingContainer = styled.div`
  cursor: pointer;
`;

const ContentRatingLabel = styled.div`
  font-size: 10px;
  margin: 1px 8px 0 0;
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.color.white};
  border-radius: 2px;
  font-weight: bold;
  padding: 0 3px;
  opacity: 0.5;

  display: flex;
  align-items: center;
`;

const ContentRatingIcon = styled(ImageBase)`
  height: 19px;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  letter-spacing: 0;
  white-space: normal;
`;

export default ContentRating;
