import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { ChanelIcon } from '@app/components/icons/PartnershipIcons';

const getChanelPrizeYear = (winnerSlug: string) => {
  if (['eduardo-williams', 'rungano-nyoni'].includes(winnerSlug)) {
    return '2022';
  }
  return '2024';
};

type ChanelPrizeProps = {
  winnerSlug: string;
};

const ChanelPrize = ({ winnerSlug }: ChanelPrizeProps) => {
  const { t } = useTranslation('cast_show');

  return (
    <Container>
      <div>
        <ChanelIcon />
      </div>
      <TextContainer>
        <Title>Chanel Next Prize</Title>
        <Caption>
          {getChanelPrizeYear(winnerSlug)} | {t('cast_show.winner')}
        </Caption>
      </TextContainer>
    </Container>
  );
};

export default ChanelPrize;

const Container = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
`;

const TextContainer = styled.div`
  padding-left: 10px;
  line-height: 1.2;
  color: ${props => props.theme.color.lightText};
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`;

const Caption = styled.span`
  font-size: 12px;
`;
