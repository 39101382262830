import styled from '@emotion/styled';

import { CastCredit } from '@app/api/resources/CastMember';
import { Film } from '@app/api/resources/Film';

import { getFilmStillUrlForExperiment } from '@app/api/services/experiments';

import useCastMembersFilms from '@app/hooks/fetch-data/useCastMembersFilms';
import useGetPerPageSize from '@app/hooks/helpers/useGetPerPageSize';
import usePopulateUserRatingForFilms from '@app/hooks/populate-store/usePopulateUserRatingForFilms';
import usePopulateUserWatchlistForFilms from '@app/hooks/populate-store/usePopulateUserWatchlistForFilms';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import CastCreditSectionLoading from '@app/components/cast-show/CastCreditSectionLoading';
import CastCreditSectionTile from '@app/components/cast-show/CastCreditSectionTile';
import CastCreditSectionTop from '@app/components/cast-show/CastCreditSectionTop';
import ShowMoreFilmsTile from '@app/components/cast-show/ShowMoreFilmsTile';
import FilmTileItem from '@app/components/grid-layout/FilmTileItem';
import TilesList from '@app/components/grid-layout/TilesList';

type CastCreditSectionProps = {
  castSlug: string;
  castMemberId: number;
  castCredit: CastCredit;
};

const CastCreditSection = ({
  castSlug,
  castMemberId,
  castCredit,
}: CastCreditSectionProps) => {
  const perPageSize = useGetPerPageSize('SMALL');
  const experiments = useAppSelector(state => state?.experiments?.experiments);
  const { castMembersFilmsPage, error, isFetching } = useCastMembersFilms({
    castMemberId,
    filterByCreditType: castCredit.type,
    perPage: perPageSize,
  });

  const isLoading = !error && !castMembersFilmsPage;

  const filmIds = castMembersFilmsPage?.films?.map((film: Film) => film.id);
  usePopulateUserRatingForFilms(filmIds);
  usePopulateUserWatchlistForFilms(filmIds);

  if (isLoading || isFetching) {
    return (
      <CastCreditSectionLoading castCredit={castCredit} castSlug={castSlug} />
    );
  }

  const showMoreLink = `/cast/${castSlug}/films/${castCredit.type}`;

  let filmTiles = [];

  const numberOfFilmsToShow = castCredit.film_count - (perPageSize - 1);

  if (castMembersFilmsPage?.films?.length > 0) {
    filmTiles = castMembersFilmsPage.films.map((film: Film, i: number) => {
      const key = `${castCredit.type}-${film.id}`;
      if (i === perPageSize - 1 && numberOfFilmsToShow > 1) {
        const filmStillImageUrl = getFilmStillUrlForExperiment(
          experiments,
          film,
        );

        return (
          <FilmTileItem key={key}>
            <ShowMoreFilmsTile
              showMoreLink={showMoreLink}
              numberOfFilmsToShow={numberOfFilmsToShow}
              filmStillUrl={filmStillImageUrl}
            />
          </FilmTileItem>
        );
      }
      return (
        <CastCreditSectionTile
          key={key}
          film={film}
          castMemberId={castMemberId}
        />
      );
    });
  }

  return (
    <Container>
      <CastCreditSectionTop
        castSlug={castSlug}
        castCredit={castCredit}
        numberOfTilesToShow={perPageSize}
      />
      <TilesList>{filmTiles}</TilesList>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 22px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 43px;
  }
`;

export default CastCreditSection;
